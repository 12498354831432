.comLogin .row .col-md-5{
	background-color: #D2D2D2;
	color: #2D2C2D;
}

.comLogin h4{
	color: #21BA87;
}

.comLogin .loading{
	background-color: #2D2C2D !important;
	text-align: center;
}