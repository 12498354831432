body{
	background-color: #2D2C2D;
	color: #D2D2D2;
  font-family: Open Sans,sans-serif
}

.border-av{ border: 1px solid #007b4d;}
.border-av-right{ border-right: 1px solid #007b4d;}
.border-av-bottom{ border-bottom: 1px solid #007b4d;}

a{ color: #fff; text-decoration: none;}
a:hover{ color: #21BA87; text-decoration: none;}

.fakeLink{ color: #fff; text-decoration: none; cursor: pointer;}
.fakeLink:hover{ color: #21BA87; text-decoration: none; cursor: pointer;}

h2,h5{ color: #21BA87;}
.linkGreen a{ color: #21BA87; }

.greenanyverse{ color: #21BA87;}
.greenanyverseLink{ color: #21BA87; cursor: pointer;}
.greenanyverseLink:hover{ color: #bde7d9;}

.redanyverse{ color: #c77979;}


.currentlink{ color: #69c9a9!important;}

.pointer{cursor: pointer;}

.pointerPlus{cursor: pointer;}
.pointerPlus:hover{color: #21BA87;}

.logout:hover{
  color: #c77979 !important;
  cursor: pointer;
}

/* TABLE LIST */
.rdt_TableRow{
  color: #ddd !important;
}

.rdt_TableRow:nth-child(even){
  background-color: #383738 !important;}

.rdt_TableRow:nth-child(odd){
  background-color: #4a494a !important;
}

.rdt_TableHeadRow{
  background-color: #1f1e1f !important;
  color: #fff;
}

.rdt_Pagination{
  background-color: #1f1e1f !important;
  color: #fff !important;
}