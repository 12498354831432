.compMenu .nav-link{
  font-weight: 400;
  font-size: 20px;
  color: #fff;
}

.compMenu .nav-link:hover{
  background-color: #454545;
  color: #aaf3d8;
}

.compMenu .submenu div a{
  font-size: 1rem !important;
}

.compMenu .submenulink{
  margin-left: 30px;
}


.compMenu .arrow {
  color: #808080;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}